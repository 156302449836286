<template lang="pug">
section#transfer-hero
  .container-lg
    h1(v-html="title")
    //- h2 {{ isRoutesPage }}
    b-row
      b-col(lg="4")
        .benefit
          svg.icon(xmlns='http://www.w3.org/2000/svg' width='40' height='44' fill='none' viewbox='0 0 40 44')
            path(fill='#fff' fill-rule='evenodd' d='M33.333 4.4h2.223C38 4.4 40 6.38 40 8.8v30.8c0 2.42-2 4.4-4.444 4.4H4.444C1.978 44 0 42.02 0 39.6L.022 8.8c0-2.42 1.956-4.4 4.422-4.4h2.223V0h4.444v4.4H28.89V0h4.444v4.4zM4.444 39.6h31.112v-22H4.444v22zm31.112-26.4H4.444V8.8h31.112v4.4zM20 24.2h11.111v11H20v-11z' clip-rule='evenodd')
          span Free Cancellation #[br] and changes
      b-col(lg="4")
        .benefit
          svg.icon(xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none' viewbox='0 0 44 44')
            path(fill='#fff' fill-rule='evenodd' d='M22 0C9.856 0 0 9.856 0 22s9.856 22 22 22 22-9.856 22-22S34.144 0 22 0zm0 39.6c-9.702 0-17.6-7.898-17.6-17.6 0-9.702 7.898-17.6 17.6-17.6 9.702 0 17.6 7.898 17.6 17.6 0 9.702-7.898 17.6-17.6 17.6zm-4.466-23.166c0 1.606 1.254 2.684 5.148 3.674 3.872 1.012 8.03 2.684 8.052 7.524-.022 3.542-2.662 5.456-6.006 6.094V37.4H19.58v-3.74c-3.3-.682-6.072-2.794-6.292-6.534h3.784c.198 2.024 1.584 3.608 5.104 3.608 3.762 0 4.62-1.892 4.62-3.058 0-1.584-.858-3.102-5.148-4.114-4.774-1.144-8.052-3.124-8.052-7.062 0-3.322 2.662-5.478 5.984-6.182V6.6h5.126v3.762c3.564.88 5.368 3.586 5.478 6.534h-3.762c-.088-2.156-1.232-3.608-4.268-3.608-2.882 0-4.62 1.298-4.62 3.146z' clip-rule='evenodd')
          span Pay online #[br] or in cash on-site
      b-col(lg="4")
        .benefit
          svg.icon(xmlns='http://www.w3.org/2000/svg' width='36' height='44' fill='none' viewbox='0 0 36 44')
            path(fill='#fff' fill-rule='evenodd' d='M18 0L0 8v12c0 11.1 7.68 21.48 18 24 10.32-2.52 18-12.9 18-24V8L18 0zm14 20c0 9.04-5.96 17.38-14 19.86C9.96 37.38 4 29.04 4 20v-9.4l14-6.22 14 6.22V20zM6 24l2.82-2.82L14 26.34l13.18-13.18L30 16 14 32l-8-8z' clip-rule='evenodd')
          span 24-hour support in English #[br] and licensed drivers
    slot
</template>

<script>
// import axios from 'axios'
import TransferSearchForm from '@/components/TransferSearchForm.vue'

// const END_POINT = 'transfer'

export default {
  name: 'TransferHero',
  components: { TransferSearchForm },
  props: {
    title: { type: String, default: 'Comfortable and Safe Transfers <br> from Airports and Hotels.' }
  },
  data: () => ({
    fromDestination: null,
    toDestination: null
  }),
  computed: {
    isRoutesPage () {
      return this.$route.name === 'transfer.routes'
    }
  },
  mounted () {
    // const vm = this
    // console.log(vm.$route.params)
    // console.log(vm.$route.query)
    // const allRequest = Object.assign({}, vm.$route.params, vm.$route.query)
    // if (vm.isRoutesPage) {
    //   axios.get(`${END_POINT}/destination/selected-destinations`, { params: allRequest })
    //     .then((r) => {
    //       vm.source.toDestinations = r.data.success ? r.data.data : []
    //     })
    // }
  }
}
</script>

<style scoped lang="scss">
section#transfer-hero{
  display:flex;
  align-items: center;
  background-image: url('../assets/transfer/transefer-bg-home.png');
  color: white;
  min-height: 314px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  h1{
    margin-bottom: 36px;
  }
  .benefit{
    display: flex;
    flex-direction: row;
    align-items: center;
    svg{
      margin-right: 10px;
    }
  }
}
</style>
